@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "jersey";
  src: url("../public/Jersey.ttf");
}

/* Scrollbar modification */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: black;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 0.375rem;
}

/* shadow */
.shadowText {
  text-shadow: 0px 0px 30px rgb(0 0 0 / 50%);
}

.glass {
  /* From https://css.glass */
  background: rgba(34, 34, 32, 0.27);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(9.8px);
  -webkit-backdrop-filter: blur(9.8px);
  border: 1px solid rgba(34, 34, 32, 0.45);
}
